import mapboxgl from 'mapbox-gl';
import {debounce} from '../js/debounce.js';

const mapEl = document.getElementById('map');
const mapToggle = document.getElementById('map-toggle');
let data = [];

if(mapEl){
    data = JSON.parse(_listingData);
}

let map = null;

const listingsMap = function(){
    mapboxgl.accessToken = 'pk.eyJ1IjoicGF0cmlja2RvdyIsImEiOiJja2Nyb2h0YnowcTFqMnVtcTB6NXVua2RtIn0.xGSkDGV7MRamRhF7VLVI7A';
    map = new mapboxgl.Map({
        container: 'map',
        style:'mapbox://styles/patrickdow/ckcwmf3ju01791io6h9up6cen',
        center: [-86.1581, 39.8868], // starting position [lng, lat]
        zoom: 10 // starting zoom
    });

    for(let i = 0; i < data.length; i++){
        const sold = data[i].sold ? `<div class="sold-banner text-strong text-camel">Sold</div>` : ``;
        const linkTitle = data[i].link.title ? data[i].link.title : `Details`;
        const link = data[i].link ? `<a href="${data[i].link.url}" target="${data[i].link.target}" class="text-strong">${linkTitle}</a>` : ``;
        const popup = new mapboxgl.Popup({ offset: 25 })
            .setHTML(
                `${sold}
                <figure class="mb-4">
                    <img src="${data[i].image}">
                </figure>
                <div class="content text-center">
                    <h4 class="text-midnight">${data[i].name}</h4>
                    <hr class="mx-auto" style="margin:0.5rem auto;">
                    ${link}
                </div>`
            );

        const el = document.createElement('div');
        el.innerHTML = 'Marker';
        el.classList.add('map-marker');

        if(data[i].location){
            const marker = new mapboxgl.Marker(el)
            .setLngLat([data[i].location.lng, data[i].location.lat])
            .setPopup(popup)
            .addTo(map);
        }

        el.addEventListener('click', function(e){
            map.flyTo({
                center:[data[i].location.lng, data[i].location.lat - .01],
                speed:0.35,
                essential: true,
                bearing: 0,
            });
        })
    }

    map.scrollZoom.disable();
}

const toggleMap = function(){
    mapToggle.addEventListener('click', function(e){
        e.preventDefault();
        const container = document.querySelector('main.listings');

        if(container.classList.contains('show-map')){
            container.classList.remove('show-map');
            this.innerHTML = 'Show Map';
        }else{
            container.classList.add('show-map');
            this.innerHTML = 'Show Grid';
            map.resize();
        }
    });
}

if(mapEl){
    listingsMap();
    
    const mapResize = debounce(function(){
        map.resize;
    }, 250);

    window.addEventListener('resize', mapResize);
}

if(mapToggle){
    toggleMap();
}