import initMap from '../js/maps.js';
import home from '../js/home.js';
import {clickOutside} from '../js/click-outside.js';

$(document).ready(function(){
    $('.home .slides').slick({ 
		dots:true, 
        adaptiveHeight:true,
        autoplay:true,
        autoplaySpeed:7000
    });

    $('.testimonial-slides').slick();
    $('.partners-slides').slick({
        slidesToShow:5,
        slidesToScroll: 1,
        infinite:true,
        responsive:[
            {
                breakpoint: 1023,
                settings:{
                    slidesToShow: 3
                }
            },
            {
                breakpoint: 767,
                settings:{
                    slidesToShow: 1
                }
            }
        ]
    });

    details();
});

function toggleDetails(){
    var detailBlocks = document.querySelectorAll('.service-details .detail-block');
    var detailToggles = document.querySelectorAll('.service-details aside a');

    if(detailBlocks.length > 0){
        for(var i = 0; i < detailToggles.length; i++){
            detailToggles[i].addEventListener('click', function(){

            });
        }
    }
}

function details(){
    $('.service-details .detail-toggle').on('click', function(e){
        var $scope = $(this).attr('href');
        
        $('.service-details .detail-block, .service-details .detail-toggle').each(function(){
            $(this).removeClass('active');
        });

		$(this).toggleClass('active');
		$($scope).addClass('active');

		if($($scope).hasClass('active')){
            var detailHeight = $($scope).scrollHeight;
            console.log(detailHeight);
			$($scope).css('max-height',detailHeight+'px');
		}else{
			$($scope).css('max-height','0');
		}

		e.preventDefault();
	});
}

const mobileMenuToggle = function(){
    let menuUtil = document.querySelector('.util-mobile-menu');
    let menu = document.querySelector('.navbar nav');

    menuUtil.addEventListener('click', function(e){
        e.preventDefault();

        if(menu.classList.contains('active')){
            menu.classList.remove('active');
            document.querySelector('body').classList.remove('mobile-menu-active');
        }else{
            menu.classList.add('active');
            document.querySelector('body').classList.add('mobile-menu-active');
        }

    })
}

const mobileExpand = function(){
    let mql = window.matchMedia('(max-width: 1023px)');
    let menu = document.querySelector('.navbar nav .menu');

    if(mql.matches){
        let parents = menu.querySelectorAll('.menu-item-has-children');

        parents.forEach(function(parent){
            parent.querySelector(':scope > a').addEventListener('click', function(e){
                e.preventDefault();
                let submenu = parent.querySelector('ul');
                let submenuHeight = submenu.scrollHeight;

                if(parent.classList.contains('active')){
                    parent.classList.remove('active');
                    submenu.style.maxHeight = 0;
                }else{
                    parent.classList.add('active');
                    submenu.style.maxHeight = '300px';
                }
            });
        });
    }
}

const searchExpand = function(){
    const toggle = document.querySelector('.navbar nav .search');
    const navbar = document.querySelector('.navbar');

    toggle.addEventListener('click', function(e){
        e.preventDefault();
        navbar.classList.toggle('show-search');
    });

    // const search = document.querySelector('.navbar .navbar-search');
    // clickOutside(search, ()=>{
    //     setTimeout(function(){
    //         if(navbar.classList.contains('show-search')){
    //             navbar.classList.remove('show-search');
    //         }
    //     }, 500);
    // })
}

const toggleDropdown = function(){
    const toggles = document.querySelectorAll('.toggle-dropdown');

    if(toggles){
        for(var i = 0; i < toggles.length; i++){
            toggles[i].addEventListener('click', function(e){
                e.preventDefault();

                this.nextElementSibling.classList.toggle('active');
            });
            
        }
    }
}

mobileMenuToggle();
mobileExpand();
searchExpand();
toggleDropdown();