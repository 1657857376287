const hero = document.querySelector('.home .hero');

if(hero){
    const img = document.querySelector('.hero-image img');

    function loaded() {
        hero.classList.add('image-loaded');
    }

    if(img.complete){
        loaded();
    }else{
        img.addEventListener('load', loaded)
        img.addEventListener('error', function(){
            console.log('error');
        });
    }
}